import "./ServicesPage.css";

import { useContext, useEffect, useState } from "react";

import SectionTitleSelector from "../../components/shared/section-title-selector/SectionTitleSelector";
import SelectedServiceDetails from "../../components/services/selected-service-details/SelectedServiceDetails";
import { AppContext } from "../../context/context-provider";
import { useLocation } from "react-router-dom";

const ServicesPage = () => {
  const { services, shouldDisplayFooter } = useContext(AppContext);
  const [selectedOption, setSelectedOption] = useState(0);

  const location = useLocation(); // Get the current route location
  useEffect(() => {
    shouldDisplayFooter(true);
  }, [location.pathname]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSelectOption = (option) => {
    setSelectedOption(option.id);
  };

  return (
    <div className="page  page-top-margin" id="services-page">
      <SectionTitleSelector
        title="Servicios"
        selectedOption={selectedOption}
        options={[
          { title: "Servicios Audiovisuales", id: 0 },
          { title: "Publicidad & Márketing", id: 1 },
          { title: "Museos & Eventos", id: 2 },
        ]}
        onSelectOption={handleSelectOption}
      />
      <SelectedServiceDetails service={services[selectedOption]} />
    </div>
  );
};

export default ServicesPage;
