import "./ColorLine.css";
import logoIcon from "../../../assets/images/logo-icon-white.svg";

//Option -> {title: string, description: string}
const ColorLine = ({ options }) => {
  return (
    <div id="color-selector-wrapper">
      <ServiceNoSelector options={options} />
    </div>
  );
};

const ServiceNoSelector = ({ options }) => {
  return (
    <div id="service-selector">
      <div className="color-line"></div>
      <div>
        <ServiceNoSelectorEntry title={options[0].title} position="left" />
        <ServiceNoSelectorEntry title={options[1].title} position="middle" />
        <ServiceNoSelectorEntry title={options[2].title} position="right" />
      </div>
    </div>
  );
};

const ServiceNoSelectorEntry = ({ title, position, onSelected }) => {
  return (
    <div className={`service-options block ${position}`}>
      <p>{title}</p>
      <img src={logoIcon} alt="icon" />
    </div>
  );
};

export default ColorLine;
