import logoIcon from "../../../assets/images/logo-icon-white.svg";
import logoAnimated from "../../../assets/images/neutr_animated.gif";
import "./HomeServices.css";
import { useState } from "react";
import TextBlock from "../../shared/text-block/TextBlock";
import ColorLine from "../../shared/color-line/ColorLine";

const HomeServices = () => {
  const [selected, setSelected] = useState(0);

  const services = [
    {
      title: "Servicios Audiovisuales",
      description:
        "Servicios AV, Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla",
    },
    {
      title: "Publicidad & Márketing",
      description:
        "Publicidad, Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla",
    },
    {
      title: "Museos & Eventos",
      description:
        "Museos & Expo, Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla",
    },
  ];

  return (
    <div id="home-services">
      <div className="logo-container">
        <img
          className="logo-animated"
          src={logoAnimated}
          alt="logo-animation"
        />
      </div>
      <div className="service-info container">
        <TextBlock text="En Neutr hacemos de forma especializada Publicidad, Museos y Animación, además de otros Servicios AV. Creamos y producimos contenidos AV para agencias, clientes/marcas y proyectos tanto locales como internacionales." />
        <br />
        <TextBlock text="Escuchamos, conceptualizamos, creamos, escribimos, diseñamos, planificamos, producimos, dirigimos, editamos, animamos y postproducimos. Contenidos para campañas publicitarias y spots. Para branded content o para otros formatos digitales y RRSS. Para experiencias inmersivas o instalaciones audiovisuales en arquitectura efímera. Con motion graphics, con animación 2D o con animación 3D." />
      </div>
      <ColorLine options={services} />
    </div>
  );
};

const ServiceSelector = ({ services, onSelected }) => {
  return (
    <div id="service-selector">
      <div className="color-line"></div>
      <ServiceSelectorEntry
        title={services[0].title}
        position="left"
        onSelected={() => onSelected(0)}
      />
      <ServiceSelectorEntry
        title={services[1].title}
        position="middle"
        onSelected={() => onSelected(1)}
      />
      <ServiceSelectorEntry
        title={services[2].title}
        position="right"
        onSelected={() => onSelected(2)}
      />
    </div>
  );
};

const ServiceSelectorEntry = ({ title, position, onSelected }) => {
  const handleMouseEnter = () => {
    onSelected();
  };

  const handleMouseLeave = () => {
    onSelected();
  };

  return (
    <div
      className={`service-options ${position}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={onSelected}
    >
      <p>{title}</p>
      <img src={logoIcon} alt="icon" />
    </div>
  );
};

const ServiceInfo = ({ service }) => {
  return (
    <div className="service-info block">
      <h3>{service.title}</h3>
      <p>{service.description}</p>
    </div>
  );
};

export default HomeServices;
