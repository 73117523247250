import "./LoadingPage.css";
import logoAnimated from "../../assets/images/neutr_animated.gif";
import { useEffect, useState } from "react";

const LoadingPage = () => {
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    //delay loading animation
    setTimeout(() => {
      setShowLoading(true);
    }, 300);
  }, []);

  return (
    <div id="loading-page">
      <img
        className={`loading-logo ${showLoading ? "show" : "hidden"}`}
        src={logoAnimated}
        alt="logo-animation"
      />
    </div>
  );
};

export default LoadingPage;
