import React, { useContext, useEffect } from "react";
import "./MethodologyPage.css";
import MethodologyLogoSelector from "../../components/methodology/logo-selector/MethodologyLogoSelector";
import SectionTitleSelector from "../../components/shared/section-title-selector/SectionTitleSelector";
import TextBlockColumn from "../../components/shared/text-block-column/TextBlockColumn";
import { AppContext } from "../../context/context-provider";
import { useLocation } from "react-router-dom";

const MethodologyPage = () => {
  const { shouldDisplayFooter } = useContext(AppContext);
  const location = useLocation(); // Get the current route location
  useEffect(() => {
    shouldDisplayFooter(true);
  }, [location.pathname]);

  const [selectedOption, setSelectedOption] = React.useState(0);
  const optionBlock = [
    {
      text: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequats et accumsan et iusto odio",
      flex: 2,
    },
    {
      text: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat",
      flex: 1,
    },
    {
      text: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat",
      flex: 1,
    },
  ];
  const options = [
    {
      title: "Texto explicativo breve sobre la metodología Audiovisuales",
    },
    {
      title: "Texto explicativo breve sobre la metodología Publicidad",
      description:
        "Publicidad On / Off, Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla",
    },
    {
      title: "Texto explicativo breve sobre la metodología Museos",
      description:
        "Museos & Expo, Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla",
    },
  ];

  const handleSelectOption = (option) => {
    setSelectedOption(option.id);
  };

  return (
    <div className="page page-top-margin " id="methodology-page">
      <SectionTitleSelector
        title="Metodologías"
        className={"methodology-selector"}
        selectedOption={selectedOption}
        options={[
          { title: "Servicios AV", id: 0 },
          { title: "Publicidad", id: 1 },
          { title: "Museos", id: 2 },
        ]}
        onSelectOption={handleSelectOption}
      />

      <div className="container">
        <TextBlockColumn
          title="Texto explicativo breve sobre la metodología audiovisual"
          blocks={[
            {
              text: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequats et accumsan et iusto odio",
              flex: 2,
            },
            {
              text: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat",
              flex: 1,
            },
            {
              text: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat",
              flex: 1,
            },
          ]}
        />
        <MethodologyLogoSelector className="selector" />
      </div>
    </div>
  );

  /*

      <SectionTitle title="Detalle Metodología" subtitle="Metodologías" />

      <ColorLineSelector options={options} />

      <MethodologyImageBullets />
      */
};

export default MethodologyPage;
