import "./SelectedServiceDetails.css";
import TextBlockTitle from "../../shared/text-block-title/TextBlockTitle";
import TextBlock from "../../shared/text-block/TextBlock";
import ReactPlayer from "react-player";

const SelectedServiceDetails = ({ service }) => {
  return (
    <>
      <div className="selected-service-details container">
        <ServiceVideo video={service.video} />
        <ServiceDescription service={service} />
      </div>

      <div className="vertical-tags">
        <ServiceTags tags={service.tags} />
      </div>
    </>
  );
};

const ServiceDescription = ({ service }) => {
  return (
    <div className="selected-service-description">
      <div className="block">
        <TextBlockTitle title={service.subtitle} fullWidth={true} columns={0} />
        <div className="selected-service-text">
          <TextBlock text={service.descriptions[0]} />
          <br />
          <TextBlock text={service.descriptions[1]} />
        </div>
      </div>

      <div className="horizontal-tags">
        <ServiceTags tags={service.tags} />
      </div>
    </div>
  );
};

const ServiceTags = ({ tags }) => {
  const groupedTags = [];
  let currentGroup = [];
  let desiredLength = 4;
  tags.forEach((tag, index) => {
    currentGroup.push(tag);
    if (currentGroup.length === desiredLength) {
      groupedTags.push(currentGroup);
      currentGroup = [];
      desiredLength = desiredLength === 4 ? 3 : 4;
    }
  });

  return (
    <div className="selected-service-tags block">
      {groupedTags.map((group, index) => (
        <div key={index} className={`tag-group group-${group.length}`}>
          {group.length === 3 && <div className="empt-tag"></div>}
          {group.map((tag, index) => (
            <div key={index} className={`tag ${index != 0 ? "spacer" : ""}`}>
              {tag}
            </div>
          ))}
          {group.length === 3 && <div className="empt-tag"></div>}
        </div>
      ))}
    </div>
  );
};

const ServiceVideo = ({ video }) => {
  return (
    <div className="selected-service-video">
      <ReactPlayer
        className="react-player fixed-bottom"
        url={video.url}
        width="100%"
        height="100%"
        playing={true}
        loop={true}
        muted={true}
        pip={false}
        controls={false}
      />
    </div>
  );
};

export default SelectedServiceDetails;
