const Gap = ({ size }) => {
  return (
    <div
      className="gap"
      style={{ display: "inline-block", width: size, height: size }}
    ></div>
  );
};

export default Gap;
