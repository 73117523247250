import TextBlockTitle from "../text-block-title/TextBlockTitle";
import "./TextBlockColumn.css";

const TextBlock = ({ title, blocks, fullWidth = true }) => {
  if (blocks.length > 1) {
    return (
      <>
        {title && (
          <TextBlockTitle title={title} columns={2} fullWidth={fullWidth} />
        )}
        <div className={`text-block ${fullWidth === true ? "" : "block"}`}>
          {blocks.map((block, index) => (
            <div
              key={"text-block-" + index}
              className="text-block-column"
              style={{ flex: block.flex || 1 }}
            >
              <p className="text-block-content">{block.text}</p>
            </div>
          ))}
        </div>
      </>
    );
  }
  return (
    <>
      <TextBlockTitle title={title} fullWidth={fullWidth} />
      <div className="text-block">
        <p>{blocks[0].text}</p>
      </div>
    </>
  );
};

export default TextBlock;
