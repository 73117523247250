import logo from "../../../assets/images/methodology/color-logo.svg";
import { useEffect, useRef, useState } from "react";
import useScreenSize from "../../shared/use-screen-size/useScreenSize";
import "./MethodologyLogoSelector.css";

const MethodologyLogoSelector = ({ className }) => {
  const baseWidth = 209;
  const baseHeight = 108;
  const dotOffset = 3;
  const baseDots = [
    {
      id: 0,
      x: 67.0,
      y: 0.0,
    },
    {
      id: 1,
      x: 110.0,
      y: 17.8,
    },
    {
      id: 2,
      x: 147.0,
      y: 34.5,
    },
  ];
  const baseSquares = [
    {
      id: 0,
      description: "Dots 1 and 2",
      startDot: 0,
      endDot: 2,
    },
  ];

  const baseInfo = {
    title: "Brief ",
    content:
      "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent",
  };
  const infos = [
    { title: baseInfo.title + "1", content: "1 " + baseInfo.content },
    { title: baseInfo.title + "2", content: "2 " + baseInfo.content },
    { title: baseInfo.title + "3", content: "3 " + baseInfo.content },
  ];

  const screenSize = useScreenSize();
  const refContainer = useRef();

  const [dots, setDots] = useState([]);
  const [selectedDot, setSelectedDot] = useState(null);

  const [squares, setSquares] = useState([]);

  useEffect(() => {
    if (refContainer.current) {
      console.log(
        refContainer.current.baseHeight,
        refContainer.current.offsetWidth,
        refContainer.current.offsetHeight
      );
      const newDots = [];
      baseDots.forEach((dot) => {
        newDots.push({
          id: dot.id,
          x: (dot.x / baseWidth) * refContainer.current.offsetWidth,
          y:
            (dot.y / baseHeight) * refContainer.current.offsetHeight -
            dotOffset,
        });
      });
      setDots(newDots);
      if (selectedDot === null) {
        setSelectedDot(newDots[0]);
      }

      //recreateSquares(newDots, selectedDot || newDots[0]);
    }
  }, [screenSize]);

  useEffect(() => {
    // const recreateSquares = (dots, selectedDot) => {
    if (dots.length === 0) return;

    console.log("recreateSquares", selectedDot);
    const squares = [];
    baseSquares.forEach((square) => {
      let startDot =
        selectedDot.id > square.startDot ? selectedDot.id : square.startDot;

      console.log("recreateSquares startDot", startDot);

      squares.push({
        ...square,
        x: dots[startDot].x,
        y: dots[startDot].y,
        width: dots[square.endDot].x - dots[startDot].x + 18,
        height: dots[square.endDot].y - dots[startDot].y + 18,
        id: square.id,
      });
    });
    setSquares(squares);
    // };
  }, [selectedDot]);

  return (
    <div
      className={`block methodoly-info-image ${className}`}
      ref={refContainer}
    >
      <img src={logo} alt="methodoly-logo" />
      <div className="dots-wrapper">
        {dots.map((dot, index) => (
          <div
            onClick={() => setSelectedDot(dot)}
            key={"dot-" + index}
            className={`dot ${selectedDot.id === dot.id ? "selected" : ""}`}
            style={{ left: dot.x, top: dot.y }}
          ></div>
        ))}
      </div>

      {squares.map((square, index) => (
        <div
          key={"square-" + index}
          className={`square ${
            selectedDot != null && selectedDot.id >= square.id ? "selected" : ""
          }`}
          style={{
            left: square.x,
            top: square.y,
            width: square.width,
            height: square.height,
          }}
        ></div>
      ))}
      {selectedDot && (
        <div className="methodology-info-text">
          <div className="methodology-info-text-inner">
            <h2>{infos[selectedDot.id].title}</h2>
            <p>{infos[selectedDot.id].content}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default MethodologyLogoSelector;
