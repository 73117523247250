import { useContext, useEffect, useState } from "react";
import "./CredentialMedia.css";
import { AppContext } from "../../../context/context-provider";
import ReactPlayer from "react-player";

const CredentialMedia = ({ selectedMedia, mediaSiblings, onCloseClick }) => {
  const [media, setMedia] = useState(null);
  const [hasNext, setHasNext] = useState(null);
  const [hasPrev, setHasPrev] = useState(null);

  const { shouldBlockScroll } = useContext(AppContext);

  useEffect(() => {
    shouldBlockScroll(selectedMedia != null);
    setMedia(selectedMedia);
    checkNextPrev(selectedMedia, mediaSiblings);
  }, [selectedMedia]);

  const checkNextPrev = (selectedMedia, mediaSiblings) => {
    if (mediaSiblings != null && selectedMedia != null) {
      console.log("mediaSiblings", mediaSiblings);
      const currentIndex = mediaSiblings.findIndex(
        (m) => m.id === selectedMedia.id
      );
      setHasNext(currentIndex < mediaSiblings.length - 1);
      setHasPrev(currentIndex > 0);
    } else {
      setHasNext(false);
      setHasPrev(false);
    }
  };

  const handlePrevPressed = () => {
    if (mediaSiblings != null && media != null) {
      const currentIndex = mediaSiblings.findIndex((m) => m.id === media.id);
      if (currentIndex > 0) {
        setMedia(mediaSiblings[currentIndex - 1]);
        checkNextPrev(mediaSiblings[currentIndex - 1], mediaSiblings);
      }
    }
  };

  const handleNextPressed = () => {
    if (mediaSiblings != null && media != null) {
      const currentIndex = mediaSiblings.findIndex((m) => m.id === media.id);
      if (currentIndex < mediaSiblings.length - 1) {
        setMedia(mediaSiblings[currentIndex + 1]);
        checkNextPrev(mediaSiblings[currentIndex + 1], mediaSiblings);
      }
    }
  };

  return (
    <div
      className={`credential-media ${media != null ? "open" : "closed"}`}
      style={{
        display: media != null ? "flex" : "none",
        opacity: media != null ? 1.0 : 0.0,
      }}
    >
      <div className="close-credential" onClick={onCloseClick}></div>

      {hasPrev && (
        <div className="media-button media-prev" onClick={handlePrevPressed}>
          <img
            className="scroll-image"
            src="/assets/images/collapse.svg"
            alt="Collapse"
          />
        </div>
      )}

      {hasNext && (
        <div className="media-button media-next" onClick={handleNextPressed}>
          <img
            className="scroll-image"
            src="/assets/images/collapse.svg"
            alt="Collapse"
          />
        </div>
      )}
      {media != null && <Media media={media} />}
    </div>
  );
};

const Media = ({ media }) => {
  const [loading, setLoading] = useState(true);
  if (media.video) {
    return (
      <>
        {loading && <div className="loading"></div>}
        <div className="player-wrapper">
          <ReactPlayer
            className="react-player fixed-bottom"
            url={media.url}
            width="100%"
            height="100%"
            playing={true}
            loop={true}
            muted={false}
            pip={false}
            controls={true}
            onReady={() => setLoading(false)}
          />
        </div>
      </>
    );
  }
  return (
    <img
      src={media.url}
      alt={media.name}
      style={{ opacity: media != null ? 1.0 : 0.0 }}
    />
  );
};

export default CredentialMedia;
