import "./AboutUsPage.css";

import logoIcon from "../../assets/images/logo-icon-white.svg";
import SectionTitle from "../../components/shared/section-title/SectionTitle";
import TextBlockColumn from "../../components/shared/text-block-column/TextBlockColumn";
import ReactPlayer from "react-player";
import { AppContext } from "../../context/context-provider";
import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";

const AboutUsPage = () => {
  const { shouldDisplayFooter } = useContext(AppContext);
  const location = useLocation(); // Get the current route location
  useEffect(() => {
    shouldDisplayFooter(true);
  }, [location.pathname]);

  return (
    <div className="page page-top-margin" id="about-us-page">
      <SectionTitle
        subtitle="Nosotros"
        title="Un equipo cercano, exigente, imaginativo y comprometido."
      />

      <div className="container">
        <TextBlockColumn
          title=""
          blocks={[
            {
              text: "Neutr cuenta con profesionales de amplia experiencia en el sector audiovisual. Cada miembro aporta un currículum sólido y diverso que enriquece nuestra labor y nos permite ofrecer soluciones creativas y efectivas a nuestros clientes. ",
              flex: 1,
            },
            {
              text: "Tras casi 20 años de experiencia, destacamos por nuestra gran capacidad de adaptación a los cambios del mercado y a las demandas de nuestros proyectos. Evolucionamos con agilidad y eficacia para mantenernos a la vanguardia de la industria audiovisual, garantizando resultados innovadores y de calidad.",
              flex: 1,
            },
            {
              text: "Aquí la colaboración y el compañerismo son pilares fundamentales. Somos personas comprometidas, empáticas y respetuosas, lo que nos permite cultivar relaciones sólidas tanto dentro como fuera del ámbito laboral. Superamos los desafíos juntos y dejamos huella con cada proyecto que emprendemos.",
              flex: 1,
            },
          ]}
        />
      </div>

      <AboutUsBottom />
    </div>
  );
};

const AboutUsBottom = () => {
  /*<p className="about-title">LEGACY</p>*/
  return (
    <div id="about-us-video-block">
      <div className="about-bg">
        <AboutUsVideo />
      </div>

      <div className="text block">
        <p className="about-content">
          Experiencia, creatividad y evolución constante.
          <br />
          Amamos los retos y garantizamos resultados.
        </p>

        <img className="about-logo" src={logoIcon} alt="logo" />
      </div>
    </div>
  );
};

const AboutUsVideo = () => {
  return (
    <ReactPlayer
      className="react-player fixed-bottom"
      url="https://firebasestorage.googleapis.com/v0/b/neutr-admin.appspot.com/o/Neutr_Web_Equipo_v02_240417.mp4?alt=media&token=c107be3d-9463-4e57-8746-1f1ef61e6099"
      width="100%"
      height="100%"
      playing={true}
      loop={true}
      muted={true}
      pip={false}
      controls={false}
    />
  );
};

export default AboutUsPage;
