import "./SelectButton.css";

import Button from "../button/Button";
import { useContext, useState } from "react";
import { AppContext } from "../../../context/context-provider";
import DropDownArrow from "../../../assets/images/dropdown-arrow.svg";

const SelectButton = ({
  selectdId,
  text,
  width,
  isSmall,
  options,
  selected,
  setSelected,
}) => {
  const { displayFloatingId, shouldDisplayFloatingId } = useContext(AppContext);
  //  const [displayOptions, setDisplayOptions] = useState(false);

  const handleButtonClick = () => {
    shouldDisplayFloatingId(displayFloatingId === null ? selectdId : null);
  };

  const handleOptionSelected = (option) => {
    setSelected(option);
    shouldDisplayFloatingId(null);
  };

  return (
    <div className="select-button-container">
      <Button
        text={text}
        width={width}
        isSmall={isSmall}
        onClick={handleButtonClick}
        trailingIcon={DropDownArrow}
      />
      <div
        className={
          displayFloatingId === selectdId
            ? "select-button-options display"
            : "select-button-options"
        }
      >
        {options.map((option, index) => (
          <SelectOption
            key={"select" + index}
            option={option}
            index={index}
            selected={selected}
            onOptionSelected={handleOptionSelected}
          />
        ))}
      </div>
    </div>
  );
};

const SelectOption = ({ option, index, selected, onOptionSelected }) => {
  const handleOptionClick = () => {
    onOptionSelected(option);
  };

  return (
    <div
      className={`select-option ${selected === option ? "selected" : ""}`}
      onClick={handleOptionClick}
    >
      {option.name}
    </div>
  );
};

export default SelectButton;
