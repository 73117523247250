import { useContext, useEffect } from "react";
import { AppContext } from "../../context/context-provider";
import { useLocation } from "react-router-dom";

const ErrorPage = () => {
  const { shouldDisplayFooter } = useContext(AppContext);
  const location = useLocation(); // Get the current route location
  useEffect(() => {
    shouldDisplayFooter(true);
  }, [location.pathname]);

  return <div>404: Not Found</div>;
};

export default ErrorPage;
