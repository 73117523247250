import React, { useContext, useEffect } from "react";

import "./ContactPage.css";
import mapImage from "../../assets/images/contact/map.svg";
import SectionTitle from "../../components/shared/section-title/SectionTitle";
import TextBlockColumn from "../../components/shared/text-block-column/TextBlockColumn";
import ContactForm from "../../components/shared/contact-form/ContactForm";
import { AppContext } from "../../context/context-provider";
import { useLocation } from "react-router-dom";

const ContactPage = () => {
  const { shouldDisplayFooter } = useContext(AppContext);
  const location = useLocation(); // Get the current route location
  useEffect(() => {
    shouldDisplayFooter(false);
  }, [location.pathname]);

  return (
    <div className="page" id="contact-page">
      <div id="contact-page-top">
        <img src={mapImage} alt="map" />
      </div>

      <SectionTitle title="Contacto" subtitle="TRABAJEMOS JUNTOS" />

      <div className="container">
        <TextBlockColumn
          title=""
          blocks={[
            {
              text: "Hemos rodado alrededor de todo el planeta. Venimos (y volvemos a menudo) de Andalucía. Pero vivimos y trabajamos principalmente en Madrid.",
              flex: 1,
            },
            {
              text: "Muchos lugares diferentes, muchos años currando juntos, muchos proyectos distintos, muchos problemas irresolubles resueltos. Cuéntanos tu proyecto o tu problema e intentaremos darle solución.",
              flex: 1,
            },
          ]}
        />

        <div id="contact-form-wrapper">
          <ContactForm className="contact-form" />
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
