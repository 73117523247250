import "./SectionTitle.css";

const SectionTitle = ({ title, subtitle, isSmall = false }) => {
  return (
    <div className={`section-title ${isSmall ? "small" : ""}`}>
      {subtitle != null && <h3>{subtitle}</h3>}
      {title != null && <h2>{title}</h2>}
    </div>
  );
};

export default SectionTitle;
