import GradientBar from "../gradient-bar/GradientBar";
import HorizontalDivider from "../horizontal-divider/HorizontalDivider";
import logo from "../../../assets/images/neutr-logo-big.svg";
import menu_icon from "../../../assets/images/menu_mobile.svg";
import "./Header.css";
import { useContext, useEffect } from "react";
import { AppContext } from "../../../context/context-provider";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  const handleNavClick = (route) => {
    navigate(route);
    window.scrollTo(0, 0);
  };

  return (
    <div id="header-container">
      <header id="header">
        <div className="logo">
          <a href="/">
            <img src={logo} alt="logo" />
          </a>
        </div>

        <div className="mobile-nav">
          <HeaderMobileNav />
        </div>

        <nav className="full-nav">
          <a onClick={() => handleNavClick("/")}>Inicio</a>
          <HorizontalDivider />
          <a onClick={() => handleNavClick("/credentials")}>Proyectos</a>
          <HorizontalDivider />
          <a onClick={() => handleNavClick("/services")}>Servicios</a>
          <HorizontalDivider />
          <a onClick={() => handleNavClick("/about-us")}>Nosotros</a>
          <HorizontalDivider />
          <a onClick={() => handleNavClick("/contact")}>Contacto</a>
        </nav>
      </header>
      <GradientBar />
    </div>
  );
};

const HeaderMobileNav = () => {
  const { displayFloatinMenu, shouldDisplayFloatingMenu } =
    useContext(AppContext);
  const options = [
    {
      text: "Inicio",
      url: "/",
    },
    {
      text: "Proyectos",
      url: "/credentials",
    },
    {
      text: "Servicios",
      url: "/services",
    },
    /*
    {
      text: "Metodologías",
      url: "/methodology",
    },
    */
    {
      text: "Nosotros",
      url: "/about-us",
    },
    {
      text: "Contacto",
      url: "/contact",
    },
  ];

  const handleButtonClick = () => {
    shouldDisplayFloatingMenu(!displayFloatinMenu);
  };

  const handleOptionSelected = (option) => {
    shouldDisplayFloatingMenu(false);
    window.location.href = option.url;
  };

  return (
    <div id="nav-button-container">
      <img src={menu_icon} alt="menu" onClick={handleButtonClick} />
      <div
        id="nav-button-options"
        className={displayFloatinMenu ? "display" : ""}
      >
        {options.map((option, index) => (
          <NavOption
            key={"select" + index}
            option={option}
            index={index}
            selected={false}
            onOptionSelected={handleOptionSelected}
          />
        ))}
      </div>
    </div>
  );
};

const NavOption = ({ option, index, selected, onOptionSelected }) => {
  const handleOptionClick = () => {
    onOptionSelected(option);
  };

  return (
    <div
      className={`nav-option ${selected === option ? "selected" : ""}`}
      onClick={handleOptionClick}
    >
      {option.text}
    </div>
  );
};

export default Header;
