import { initializeApp } from "firebase/app";

const app = initializeApp({
  projectId: "neutr-admin",
  apiKey: "AIzaSyCR62xWLlMkZJsjKULuKz_vZ3iKaRbqCO0",
  authDomain: "neutr-admin.firebaseapp.com",
});
//const functions = getFunctions(app, "europe-west3");
//connectFunctionsEmulator(functions, "127.0.0.1", 5001);

const isDev = false;
/*
const getCredentials = async () => {
  const getCredentialsCall = httpsCallable(functions, "callGetCredentials");
  return getCredentialsCall({}).then((result) => {
    const data = result.data;
    return data;
  });
};

const getClients = async () => {
  const getCredentialsCall = httpsCallable(functions, "callGetClients");
  return getCredentialsCall({}).then((result) => {
    const data = result.data;
    return data;
  });
};

const getMedias = async () => {
  const getMediasCall = httpsCallable(functions, "callGetMedias");
  return getMediasCall({}).then((result) => {
    const data = result.data;
    return data;
  });
};
*/
const getAllData = async () => {
  const url = isDev
    ? "http://127.0.0.1:5001/neutr-admin/europe-west3/apiGetAll"
    : "https://apigetall-fhtptg3xia-ey.a.run.app";
  try {
    const response = await fetch(url);

    console.log(response);
    if (!response.ok) {
      throw new Error(`Response status: ${response.status}`);
    }

    const json = await response.json();
    const data = json;
    console.log(data);
    return data;
  } catch (error) {
    console.error(error.message);
  }
  return;
};

const saveForm = async (formData) => {
  const url = isDev
    ? "http://127.0.0.1:5001/neutr-admin/europe-west3/apiSaveForm"
    : "https://apisaveform-fhtptg3xia-ey.a.run.app";
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify({
        name: formData.name || "",
        email: formData.email || "",
        service: formData.service || "",
        message: formData.message || "",
      }),
    });

    console.log(response);
    if (!response.ok) {
      return { status: "error", error: response.status };
    }
    return { status: "success" };
  } catch (error) {
    console.error(error.message);
  }
  return { status: "error", error: "" };
};

export { getAllData, saveForm }; // getClients, getCredentials, getMedias };
