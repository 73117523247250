import "./Button.css";

const Button = ({
  id,
  text,
  width,
  isSmall,
  isInverted,
  fillBackground,
  onClick,
  className,
  trailingIcon,
}) => {
  return (
    <div
      id={id}
      className={`button ${isSmall ? "small" : ""} ${
        trailingIcon ? "trailing" : ""
      } ${className ? className : ""} ${
        fillBackground ? "fill-background" : ""
      } ${isInverted ? "inverted" : ""} ${width ? "fixed" : ""}`}
      onClick={onClick}
    >
      {!trailingIcon && text}
      {trailingIcon && (
        <>
          <span>{text}</span> <img src={trailingIcon} />
        </>
      )}
    </div>
  );
};

export default Button;
