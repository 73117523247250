import "../section-title/SectionTitle.css";
import "./SectionTitleSelector.css";

const SectionTitleSelector = ({
  title,
  className,
  selectedOption,
  options,
  onSelectOption,
}) => {
  return (
    <div className={`section-title ${className}`}>
      <h3>{title}</h3>
      <div className="section-title-options">
        {options.map((option, index) => (
          <div
            className="section-option-container"
            key={"section-option-" + index}
          >
            {index != 0 && <SectionTitleSelectorHorizontalDivider />}
            <h2
              onClick={() => onSelectOption(option)}
              className={
                option.id === selectedOption
                  ? "section-option selected"
                  : "section-option"
              }
            >
              {option.title}
            </h2>
          </div>
        ))}
      </div>
    </div>
  );
};

const SectionTitleSelectorHorizontalDivider = () => {
  return <div className="section-horizontal-divider"></div>;
};

export default SectionTitleSelector;
