import "./TextBlockTitle.css";

const TextBlockTitle = ({ title, columns = 1, fullWidth, className }) => {
  if (columns === 0) {
    return (
      <div
        className={`text-block ${
          fullWidth === true ? "" : "block"
        } ${className}`}
      >
        <div className="text-block-column">
          <h2 className="text-block-title">{title}</h2>
        </div>
      </div>
    );
  }
  if (columns === 2) {
    return (
      <div
        className={`text-block ${
          fullWidth === true ? "" : "block"
        } ${className}`}
      >
        <div className="text-block-column">
          <h2 className="text-block-title">{title}</h2>
        </div>
        <div className="text-block-column"></div>
        <div className="text-block-column"></div>
      </div>
    );
  }
  return (
    <div className={`text-block ${fullWidth === true ? "" : "block"}`}>
      <div className="text-block-column">
        <h2 className="text-block-title">{title}</h2>
      </div>
      <div className="text-block-column"></div>
    </div>
  );
};

export default TextBlockTitle;
