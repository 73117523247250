import { Outlet, RouterProvider, createBrowserRouter } from "react-router-dom";
import "./App.css";
import ErrorPage from "../error/ErrorPage";
import HomePage from "../home/HomePage";
import CredentialsPage from "../credentials/CredentialsPage";
import { AppContext, AppContextProvider } from "../../context/context-provider";
import Header from "../../components/shared/header/Header";
import Footer from "../../components/shared/footer/Footer";
import ContactPage from "../contact/ContactPage";
import ServicesPage from "../services/ServicesPage";
import MethodologyPage from "../methodology/MethodologyPage";
import AboutUsPage from "../about-us/AboutUsPage";
import { useContext, useEffect } from "react";
import LegalPage from "../legal/LegalPage";
import PrivacyPage from "../privacy/PrivacyPage";
import LoadingPage from "../loading/LoadingPage";

const App = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <RouteBody />,
      children: [
        {
          index: true,
          element: <HomePage />,
          errorElement: <ErrorPage />,
        },
        {
          path: "credentials/:credentialId",
          element: <CredentialsPage />,
        },
        {
          path: "credentials",
          element: <CredentialsPage />,
        },
        {
          path: "services",
          element: <ServicesPage />,
        },
        {
          path: "methodology",
          element: <MethodologyPage />,
        },
        {
          path: "about-us",
          element: <AboutUsPage />,
        },
        {
          path: "contact",
          element: <ContactPage />,
        },
        {
          path: "legal",
          element: <LegalPage />,
        },
        {
          path: "privacy",
          element: <PrivacyPage />,
        },
        {
          path: "legal",
          element: <LegalPage />,
        },
        {
          path: "privacy",
          element: <PrivacyPage />,
        },
      ],
    },
  ]);

  return (
    <AppContextProvider>
      <AppBody router={router} />
    </AppContextProvider>
  );
};

const AppBody = ({ router }) => {
  const {
    allReady,
    displayFloating,
    displayFloatinMenu,
    shouldDisplayFloating,
    shouldDisplayFloatingMenu,
  } = useContext(AppContext);
  const { blockScroll } = useContext(AppContext);

  useEffect(() => {
    if (blockScroll) {
      document.body.classList.add("lock-scroll");
    } else {
      document.body.classList.remove("lock-scroll");
    }
  }, [blockScroll]);

  const handleClick = () => {
    shouldDisplayFloating(false);
    shouldDisplayFloatingMenu(false);
  };

  return (
    <>
      {!allReady && <LoadingPage />}
      {allReady && (
        <div id="main" className="App">
          <div
            id="hide-box"
            className={displayFloating || displayFloatinMenu ? "display" : ""}
            onClick={handleClick}
          ></div>
          <RouterProvider router={router} />
        </div>
      )}
    </>
  );
};

const RouteBody = () => {
  return (
    <>
      <Header />
      <div id="header-gap" />
      <Outlet />
      <Footer />
    </>
  );
};

const AppBody2 = ({ router }) => {
  const {
    allReady,
    displayFloating,
    displayFloatinMenu,
    shouldDisplayFloating,
    shouldDisplayFloatingMenu,
  } = useContext(AppContext);
  const { blockScroll } = useContext(AppContext);

  useEffect(() => {
    if (blockScroll) {
      document.body.classList.add("lock-scroll");
    } else {
      document.body.classList.remove("lock-scroll");
    }
  }, [blockScroll]);

  const handleClick = () => {
    shouldDisplayFloating(false);
    shouldDisplayFloatingMenu(false);
  };

  return (
    <>
      {!allReady && <LoadingPage />}
      {allReady && (
        <div id="main" className="App">
          <div
            id="hide-box"
            className={displayFloating || displayFloatinMenu ? "display" : ""}
            onClick={handleClick}
          ></div>
          <Header />
          <div id="header-gap" />

          <RouterProvider router={router} />
          <Footer />
        </div>
      )}
    </>
  );
};

export default App;
