import "./ContactForm.css";
import { useNavigate } from "react-router-dom";

import Button from "../../shared/button/Button";
import { useRef, useState } from "react";
import { saveForm } from "../../../context/firebase-repository";

const ContactForm = ({ className, inverted, onlyButton, onClick }) => {
  if (onlyButton) {
    return OnlyButtonForm({ className, inverted, onClick });
  }
  return FullForm({ className, inverted });
};

const OnlyButtonForm = ({ className, inverted, onClick }) => {
  return (
    <div
      className={`contact-form contact-form-only-button ${className} ${
        inverted ? "inverted" : ""
      }`}
    >
      <div className="contact-left">
        <p className="contact-info only-button only-no-mobile">
          ¿Tienes alguna duda o alguna propuesta? ¿Interesado en colaborar en
          proyectos emocionantes? No dudes en ponerte en contacto con nosotros.
        </p>
        <p className="contact-info only-button only-mobile">
          ¿Tienes alguna duda o alguna propuesta?
        </p>
      </div>

      <div className="contact-right">
        <Button
          className="form-button"
          text="Contactar"
          isInverted={inverted}
          fillBackground={true}
          onClick={onClick}
        />
      </div>
    </div>
  );
};

const FullForm = ({ className, inverted }) => {
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const formRef = useRef(null);

  const handleClick = async () => {
    if (submitting) return;
    let form = formRef.current;
    // Prevent the browser from reloading the page
    // e.preventDefault();

    // Read the form data
    //  const form = e.target;
    const name = form.elements["name"].value;
    const email = form.elements["email"].value;
    const service = form.elements["service"].value;
    const message = form.elements["message"].value;

    setSubmitting(true);

    // Send the form data to the server
    let response = await saveForm({ name, email, service, message });
    console.log(response);
    setSubmitting(false);
    if (response.status === "success") {
      setSubmitted(true);
    }
    // const formData = new FormData(form);

    // You can pass formData as a fetch body directly:
    // fetch("/some-api", { method: form.method, body: formData });

    // Or you can work with it as a plain object:
    //  const formJson = Object.fromEntries(formData.entries());
    // console.log(formJson);
  };

  return (
    <form
      className={`contact-form ${className} ${inverted ? "inverted" : ""}`}
      ref={formRef}
    >
      <h4 className="contact-title">Contacto</h4>

      {!submitted && (
        <>
          <p className="contact-info">
            ¿Tienes alguna duda o alguna propuesta? ¿Interesado en colaborar en
            proyectos emocionantes? No dudes en ponerte en contacto con
            nosotros.
          </p>

          <div className="contact-block">
            <input
              type="text"
              id="form-name"
              name="name"
              placeholder="NOMBRE"
              required
            />
            <input
              type="text"
              id="form-email"
              name="email"
              placeholder="EMAIL"
              required
            />
            <input
              type="text"
              id="form-service"
              name="service"
              placeholder="SERVICIO"
              required
            />
          </div>
          <div className="contact-block">
            <input
              type="text"
              id="form-message"
              name="message"
              placeholder="MENSAJE"
              required
            />
            <Button
              text={submitting ? "Enviando.." : "Enviar"}
              isInverted={inverted}
              fillBackground={true}
              onClick={handleClick}
            />
          </div>
          <ContactLegal />
        </>
      )}
      <div
        className={`contact-form-confirmation ${submitted ? "submitted" : ""}`}
      >
        <p>Tu formulario se ha registrado correctamente, muchas gracias</p>
      </div>
    </form>
  );
};

const ContactLegal = () => {
  return (
    <div>
      <p className="form-legal">
        Al enviar aceptas nuestro{" "}
        <a href="/legal" target="_blank">
          aviso legal
        </a>{" "}
        y nuestra{" "}
        <a href="/privacy" target="_blank">
          política de privacidad
        </a>
      </p>
    </div>
  );
};

export default ContactForm;
