import "./HorizontalDivider.css";

const HorizontalDivider = ({ inverted = false, className = "" }) => {
  return (
    <div
      className={`horizontal-divider ${
        inverted ? "inverted" : ""
      } ${className}`}
    ></div>
  );
};

export default HorizontalDivider;
