const preloadImage = (src) => {
  const img = new Image();
  img.src = src;
};

const preloadImages = (data) => {
  let homeImagesUrls = [];
  let imagesHash = {};
  data.medias.forEach((media) => {
    imagesHash[media.id] = media.url;
  });
  let homeCredentials = data.credentials.filter(
    (credential) => credential.displayInHome === true
  );
  homeCredentials.forEach((credential) => {
    if (
      credential.verticalImage != null &&
      imagesHash[credential.verticalImage] != null
    ) {
      homeImagesUrls.push(imagesHash[credential.verticalImage]);
    }
  });

  for (let i = 0; i < homeImagesUrls.length; i++) {
    preloadImage(homeImagesUrls[i]);
  }
};

export { preloadImages };
