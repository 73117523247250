import { useContext, useEffect } from "react";

import SectionTitle from "../../components/shared/section-title/SectionTitle";
import { AppContext } from "../../context/context-provider";
import { useLocation } from "react-router-dom";

const LegalPage = () => {
  const { shouldDisplayFooter } = useContext(AppContext);
  const location = useLocation(); // Get the current route location
  useEffect(() => {
    shouldDisplayFooter(true);
  }, [location.pathname]);

  return (
    <div className="page page-top-margin" id="legal-page">
      <SectionTitle subtitle="Aviso Legal" />
      <br></br>
      <br></br>
      <div className="container">
        <p>
          En cumplimiento del artículo 10 de la Ley 34/2.002, de 11 de julio, de
          Servicios de la Sociedad de la Información y Comercio Electrónico, les
          informamos que los datos identificativos de la empresa son los
          siguientes:
        </p>
        <p className="legal-under">Datos de la empresa</p>
        <p>
          Denominación social:{" "}
          <span className="legal-bold">NEUTRWORKS 2020 S.L.</span>
        </p>
        <p>
          CIF: <span className="legal-bold">B01814938</span>
        </p>
        <p>
          Domicilio social:{" "}
          <span className="legal-bold">
            Calle del Camino de los Vinateros 106, Oficinas 1 y 2, 28030, Madrid
            (Spain)
          </span>
        </p>
        <p>
          Email: <span className="legal-bold">hola@neutr.com</span>
        </p>
        <p className="legal-under">Objeto social</p>
        <p>
          1º La producción de contenidos audiovisuales, digitales,
          cinematográficos, publicitarios, formativos, animación, interactivos y
          multimedia en cualquier formato de distribución, explotación o
          consumo.
        </p>
        <p>
          2º La intermediación en la prestación de servicios audiovisuales
          relacionado con la producción de contenidos audiovisuales, digitales,
          cinematográficos, publicitarios, formativos, animación, interactivos y
          multimedia.
        </p>
        <p>
          3º La producción, comercialización y emisión de programas de
          televisión, de acuerdo con la legislación especial vigente sobre la
          materia; producción, coproducción, rodaje, y comercialización de
          películas cinematográficas, y, en general, de toda clase de obras
          audiovisuales, incluyendo spots publicitarios; doblaje, sonorización,
          montaje, edición y postproducción de obras audiovisuales.
        </p>
        <p>
          4º La edición musical y discográfica, y, en particular, la adquisición
          y comercialización en cualquier forma de derechos musicales.
        </p>
        <p>
          5º La realización de proyectos editoriales, de artes gráficas o de
          cualquier otra naturaleza en Internet, así como la creación y venta de
          páginas web.
        </p>
        <p>
          6º La consultoría y aplicación estratégica de creatividad,
          comunicación y desarrollo de contenidos creativos.
        </p>
        <p>7º Alquiler de materiales e infraestructuras.</p>
        <p>8º Construcción, instalaciones y mantenimiento.</p>
        <p>
          9º Comercio al por mayor y al por menor. Distribución comercial,
          importación y exportación.
        </p>
        <p>10º Actividades inmobiliarias.</p>
        <p>11º Industrias manufactureras y textiles.</p>
        <p>12º Turismo, hostelería y restauración.</p>
        <p>
          13º Prestación de servicios variados, actividades de gestión y
          administración. Servicios educativos, sanitarios, de ocio y
          entretenimiento.
        </p>
        <p>
          NEUTRWORKS 2020 S.L. no puede asumir ninguna responsabilidad derivada
          del uso incorrecto, inapropiado o ilícito de la información aparecida
          en la página de Internet www.neutr.com.
        </p>
        <p>
          Con los límites establecidos en la ley, NEUTRWORKS 2020 S.L. no asume
          ninguna responsabilidad derivada de la falta de veracidad, integridad,
          actualización y precisión de los datos o informaciones que se
          contienen en sus páginas de Internet.
        </p>
        <p>
          Los contenidos e información no vinculan a NEUTRWORKS 2020 S.L. ni
          constituyen opiniones, consejos o asesoramiento legal de ningún tipo
          pues se trata meramente de un servicio ofrecido con carácter
          informativo y divulgativo.
        </p>
        <p>
          La página de Internet www.neutr.com puede contener enlaces (links) a
          otras páginas de terceras partes que NEUTRWORKS 2020 S.L. no puede
          controlar. Por lo tanto, NEUTRWORKS 2020 S.L. no puede asumir
          responsabilidades por el contenido que pueda aparecer en páginas de
          terceros.
        </p>
        <p>
          Los textos, imágenes, sonidos, animaciones, software y el resto de
          contenidos incluidos en este website son propiedad exclusiva de
          NEUTRWORKS 2020 S.L. o sus licenciantes. Cualquier acto de
          transmisión, distribución, cesión, reproducción, almacenamiento o
          comunicación pública total o parcial, debe contar con el
          consentimiento expreso de NEUTRWORKS 2020 S.L.
        </p>
        <p>
          Asimismo, para acceder a algunos de los servicios que NEUTRWORKS 2020
          S.L. ofrece a través del website deberá proporcionar algunos datos de
          carácter personal. En cumplimiento de lo establecido en el Reglamento
          (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de
          2016, relativo a la protección de las personas físicas en lo que
          respecta al tratamiento de datos personales y a la libre circulación
          de estos datos le informamos que, mediante la cumplimentación de los
          presentes formularios, sus datos personales quedarán incorporados y
          serán tratados en los ficheros de NEUTRWORKS 2020 S.L. con el fin de
          poderle prestar y ofrecer nuestros servicios así como para informarle
          de las mejoras del sitio Web. Asimismo, le informamos de la
          posibilidad de que ejerza los derechos de acceso, rectificación,
          cancelación y oposición de sus datos de carácter personal, manera
          gratuita mediante email a info@neutr.com o en la dirección Calle del
          Camino de los Vinateros 106, Oficinas 1 y 2, 28030, Madrid (Spain)
        </p>
      </div>
    </div>
  );
};

export default LegalPage;
