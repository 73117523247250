const services = [
  {
    title: "Servicios Audiovisuales",
    subtitle: "Creamos y producimos todo tipo de contenido audiovisual",
    descriptions: [
      "Aparte de nuestra especialización en Museos y Publicidad, nuestros profesionales tienen más de 20 años de experiencia en la producción de contenidos audiovisuales de todo tipo. Cualquier necesidad derivada de la creación de contenido podemos abarcarla sin dudar.",
      "Nos encargamos de todas las tareas relacionadas con el sector audiovisual que existen. Y si no existen, las podemos inventar. ",
    ],
    video: {
      id: "1",
      name: "Proyecto 1",
      url: "https://firebasestorage.googleapis.com/v0/b/neutr-admin.appspot.com/o/services_audiovisual_services.mp4?alt=media&token=8b16cf80-796b-44e3-aaf5-15621db9b73f",
    },
    tags: [
      "Creatividad",
      "Contenido",
      "Guión",
      "Desarrollo Audiovisual",
      "Preproducción",
      "Rodaje Beauty",
      "Rodaje B-Roll",
      "Rodaje Entrevistas",
      "Rodaje Aéreo",
      "Rodajes en España",
      "Rodajes Internacionales",
      "Grabación Eventos",
      "Cobertura Audiovisual",
      "Service de Producción",
      "Videos RRSS",
      "Video Presentación",
      "Videos Corporativos",
      "Vídeo de Marca",
      "Videoclips",
      "Making Off",
      "Postproducción",
      "Edición de Vídeo",
      "VFX",
      "Etalonaje",
      "Edición de Sonido",
      "Locuciones",
      "Música",
      "Infografía",
      "Motion Graphics",
      "Animación 2D",
      "Animación 3D",
      "Locuciones",
      "Infografías",
      "Ilustraciones",
    ],
  },
  {
    title: "Publicidad & Márketing",
    subtitle: "Especializados en campañas publicitarias",
    descriptions: [
      "Contamos con un amplio bagaje en la creación y ejecución de campañas publicitarias impactantes y efectivas. Desarrollamos propuestas innovadoras que conectan con el público objetivo y generan resultados adaptados a las expectativas de nuestros clientes, gracias a un equipo de profesionales creativos con trayectorias contrastadas.",
      "Desde la conceptualización hasta la ejecución, nos comprometemos a transmitir el mensaje de nuestras marcas de una manera única y memorable, utilizando las últimas tendencias y herramientas audiovisuales para alcanzar el éxito deseado.",
    ],
    video: {
      id: "2",
      name: "Proyecto 1",
      url: "https://firebasestorage.googleapis.com/v0/b/neutr-admin.appspot.com/o/services_advertising_marketing.mp4?alt=media&token=e969e622-16aa-4afe-832d-fcb3aacd1ed2",
    },
    tags: [
      "Campañas Publicidad",
      "Campañas RRSS",
      "Campañas Multiplataforma",
      "Campañas OOH",
      "Campañas Internacionales",
      "Copywriting",
      "Spots TV",
      "Campañas exteriores",
      "Campañas Cine",
      "Cuñas radio",
      "Gráficos",
      "Fotografía",
      "Rodaje Aéreo",
      "Rodaje Submarino",
      "Fotografía para lonas y mupis",
    ],
  },
  {
    title: "Museos & Eventos",
    subtitle:
      "Creamos y producimos contenidos complejos e instalaciones para Museos y Eventos",
    descriptions: [
      "Tenemos amplia experiencia en la gestión integral de instalaciones audiovisuales para museos y exposiciones. Coordinamos y ejecutamos todos los procesos relacionados con la implementación de tecnología audiovisual en entornos culturales, ayudando a crear experiencias inmersivas y memorables para los visitantes.",
      "Con un enfoque meticuloso y creativo, transformamos espacios en escenarios interactivos que potencian la narrativa visual y enriquecen la exhibición de contenidos artísticos o educativos. Contamos con la especialización necesaria para llevar a cabo cualquier proyecto museístico con un nivel superior de excelencia y profesionalismo.",
    ],
    video: {
      id: "3",
      name: "Proyecto 1",
      url: "https://firebasestorage.googleapis.com/v0/b/neutr-admin.appspot.com/o/services_museum_events.mov?alt=media&token=95f43477-0904-4591-ada9-9fd0140e2803",
    },
    tags: [
      "Museística",
      "Diseño Contenidos",
      "Exposiciones",
      "Arquitectura Efímera",
      "Muestras itinerantes",
      "Instalaciones en exteriores",
      "Instalaciones en interiores",
      "Instalaciones museográficas",
      "Formatos Especiales ",
      "Gran Formato (20K | 10K)",
      "Escenografía",
      "Modulos expositores",
      "Collage Multipantalla",
      "Proyecciones ",
      "Proyecciones Inmersivas",
      "Video Mapping",
      "Animaciones 2D",
      "Animaciones 3D",
      "VFX",
      "Pantallas táctiles",
      "Pantallas interactivas",
      "Gestión material de archivo",
      "Ambientes Sonoros",
      "PARALLAX",
      "Piezas interactivas",
    ],
  },
];

export { services };
