import "./TextBlock.css";

const TextBlock = ({ text }) => {
  return (
    <div className="individual-text-block">
      <p>{text}</p>
    </div>
  );
};

export default TextBlock;
