import { useLocation, useNavigate } from "react-router-dom";

import "./HomePage.css";
import Button from "../../components/shared/button/Button";
import HomeCredentials from "../../components/home/home-credentials/HomeCredentials";
import HomeClients from "../../components/home/home-clients/HomeClients";
import HomeServices from "../../components/home/home-services/HomeServices";
import Gap from "../../components/shared/gap/Gap";
import HomeVideo from "../../components/home/home-video/HomeVideo";
import { useContext, useEffect } from "react";
import { AppContext } from "../../context/context-provider";

const HomePage = () => {
  const navigate = useNavigate();
  const { clients, homeCredentials, shouldDisplayFooter } =
    useContext(AppContext);

  const location = useLocation(); // Get the current route location

  useEffect(() => {
    shouldDisplayFooter(true);
  }, [location.pathname]);

  const handleCredentialsClick = () => {
    navigate("/credentials");
  };

  const handleServicesClick = () => {
    navigate("/services");
  };

  const handleCredentialClick = (credential) => {
    navigate(`/credentials/${credential.id}`);
  };

  return (
    <div className="page" id="home-page">
      <HomeVideo />
      <HomeServices />
      <Gap size={40} />
      <div id="view-all-projects">
        <Button
          id="view-all-projects"
          text="Ver servicios"
          onClick={handleServicesClick}
        />
      </div>
      <Gap size={60} />
      <HomeCredentials
        credentials={homeCredentials}
        onCredentialClick={handleCredentialClick}
      />
      <Gap size={40} />
      <div id="view-all-projects">
        <Button
          id="view-all-projects"
          text="Ver Proyectos"
          onClick={handleCredentialsClick}
        />
      </div>
      <HomeClients clients={clients} />
    </div>
  );
};

export default HomePage;
