import { useContext, useEffect } from "react";
import { AppContext } from "../../context/context-provider";
import { useLocation } from "react-router-dom";
import SectionTitle from "../../components/shared/section-title/SectionTitle";

const PrivacyPage = () => {
  const { shouldDisplayFooter } = useContext(AppContext);
  const location = useLocation(); // Get the current route location
  useEffect(() => {
    shouldDisplayFooter(true);
  }, [location.pathname]);

  return (
    <div className="page page-top-margin" id="legal-page">
      <SectionTitle subtitle="Política de privacidad" />
      <br></br>
      <br></br>
      <div className="container">
        <p>Protección de datos de carácter personal según la LOPD</p>
        <p>
          NEUTRWORKS 2020 S.L., en aplicación de la normativa vigente en materia
          de protección de datos de carácter personal, informa que los datos
          personales que se recogen a través de los formularios del Sitio web:
          www.neutr.com, se incluyen en los ficheros automatizados específicos
          de usuarios de los servicios de NEUTRWORKS 2020 S.L.
        </p>
        <p>
          La recogida y tratamiento automatizado de los datos de carácter
          personal tiene como finalidad el mantenimiento de la relación
          comercial y el desempeño de tareas de información, formación,
          asesoramiento y otras actividades propias de NEUTRWORKS 2020 S.L.
        </p>
        <p>
          Estos datos únicamente serán cedidos a aquellas entidades que sean
          necesarias con el único objetivo de dar cumplimiento a la finalidad
          anteriormente expuesta.
        </p>
        <p>
          NEUTRWORKS 2020 S.L. adopta las medidas necesarias para garantizar la
          seguridad, integridad y confidencialidad de los datos conforme a lo
          dispuesto en el Reglamento (UE) 2016/679 del Parlamento Europeo y del
          Consejo, de 27 de abril de 2016, relativo a la protección de las
          personas físicas en lo que respecta al tratamiento de datos personales
          y a la libre circulación de los mismos.
        </p>
        <p>
          El usuario podrá en cualquier momento ejercitar los derechos de
          acceso, oposición, rectificación y cancelación reconocidos en el
          citado Reglamento (UE). El ejercicio de estos derechos puede
          realizarlo el propio usuario a través de email a: info@neutr.com o en
          la dirección: Calle del Camino de los Vinateros 106, Oficinas 1 y 2,
          28030, Madrid (Spain)
        </p>
        <p>
          El usuario manifiesta que todos los datos facilitados por él son
          ciertos y correctos, y se compromete a mantenerlos actualizados,
          comunicando los cambios a NEUTRWORKS 2020 S.L.
        </p>
        <p className="legal-under">
          Finalidad del tratamiento de los datos personales:
        </p>
        <p>¿Con qué finalidad trataremos tus datos personales?</p>
        <p>
          En NEUTRWORKS 2020 S.L., trataremos tus datos personales recabados a
          través del Sitio Web: www.neutr.com con las siguientes finalidades:
        </p>
        <p>
          1. En caso de contratación de los bienes y servicios ofertados a
          través de NEUTRWORKS 2020 S.L., para mantener la relación contractual,
          así como la gestión, administración, información, prestación y mejora
          del servicio.
        </p>
        <p>
          2. Envío de información solicitada a través de los formularios
          dispuestos en www.neutr.com.
        </p>
        <p>
          3. Remitir boletines (newsletters), así como comunicaciones
          comerciales de promociones y/o publicidad de NEUTRWORKS 2020 S.L. y
          del sector.
        </p>
        <p>
          Te recordamos que puedes oponerte al envío de comunicaciones
          comerciales por cualquier vía y en cualquier momento, remitiendo un
          correo electrónico a la dirección indicada anteriormente.
        </p>
        <p>
          Los campos de dichos registros son de cumplimentación obligatoria,
          siendo imposible realizar las finalidades expresadas si no se aportan
          esos datos.
        </p>
        <p>¿Por cuánto tiempo se conservan los datos personales recabados?</p>
        <p>
          Los datos personales proporcionados se conservarán mientras se
          mantenga la relación comercial o no solicites su supresión y durante
          el plazo por el cuál pudieran derivarse responsabilidades legales por
          los servicios prestados.
        </p>
        <p className="legal-under">Legitimación</p>
        <p>
          El tratamiento de tus datos se realiza con las siguientes bases
          jurídicas que legitiman el mismo:
        </p>
        <p>
          1. La solicitud de información y/o la contratación de los servicios de
          NEUTRWORKS 2020 S.L., cuyos términos y condiciones se pondrán a tu
          disposición en todo caso, de forma previa a una eventual contratación.
        </p>
        <p>
          2. El consentimiento libre, específico, informado e inequívoco, en
          tanto que te informamos poniendo a tu disposición la presente política
          de privacidad, que tras la lectura de la misma, en caso de estar
          conforme, puedes aceptar mediante una declaración o una clara acción
          afirmativa, como el marcado de una casilla dispuesta al efecto.
        </p>
        <p>
          En caso de que no nos facilites tus datos o lo hagas de forma errónea
          o incompleta, no podremos atender tu solicitud, resultando del todo
          imposible proporcionarte la información solicitada o llevar a cabo la
          contratación de los servicios.
        </p>
        <p className="legal-under">Derechos propiedad intelectual</p>
        <p>
          NEUTRWORKS 2020 S.L. es titular de todos los derechos de autor,
          propiedad intelectual, industrial, "know how" y cuantos otros derechos
          guardan relación con los contenidos del sitio web www.neutr.com y los
          servicios ofertados en el mismo, así como de los programas necesarios
          para su implementación y la información relacionada.
        </p>
        <p>
          No se permite la reproducción, publicación y/o uso no estrictamente
          privado de los contenidos, totales o parciales, del sitio web
          www.neutr.com sin el consentimiento previo y por escrito.
        </p>
        <p className="legal-under">Propiedad intelectual del software</p>
        <p>
          El usuario debe respetar los programas de terceros puestos a su
          disposición por NEUTRWORKS 2020 S.L., aun siendo gratuitos y/o de
          disposición pública.
        </p>
        <p>
          NEUTRWORKS 2020 S.L. dispone de los derechos de explotación y
          propiedad intelectual necesarios del software.
        </p>
        <p>
          El usuario no adquiere derecho alguno o licencia por el servicio
          contratado, sobre el software necesario para la prestación del
          servicio, ni tampoco sobre la información técnica de seguimiento del
          servicio, excepción hecha de los derechos y licencias necesarios para
          el cumplimiento de los servicios contratados y únicamente durante la
          duración de los mismos.
        </p>
        <p>
          Para toda actuación que exceda del cumplimiento del contrato, el
          usuario necesitará autorización por escrito por parte de NEUTRWORKS
          2020 S.L., quedando prohibido al usuario acceder, modificar,
          visualizar la configuración, estructura y ficheros de los servidores
          propiedad de NEUTRWORKS 2020 S.L., asumiendo la responsabilidad civil
          y penal derivada de cualquier incidencia que se pudiera producir en
          los servidores y sistemas de seguridad como consecuencia directa de
          una actuación negligente o maliciosa por su parte.
        </p>
        <p className="legal-under">
          Propiedad intelectual de los contenidos alojados
        </p>
        <p>
          Se prohíbe el uso contrario a la legislación sobre propiedad
          intelectual de los servicios prestados por NEUTRWORKS 2020 S.L. y, en
          particular de:
        </p>
        <p>
          • La utilización que resulte contraria a las leyes españolas o que
          infrinja los derechos de terceros.
        </p>
        <p>
          • La publicación o la transmisión de cualquier contenido que, a juicio
          de NEUTRWORKS 2020 S.L., resulte violento, obsceno, abusivo, ilegal,
          racial, xenófobo o difamatorio.
        </p>
        <p>
          • Los cracks, números de serie de programas o cualquier otro contenido
          que vulnere derechos de la propiedad intelectual de terceros.
        </p>
        <p>
          • La recogida y/o utilización de datos personales de otros usuarios
          sin su consentimiento expreso o contraviniendo lo dispuesto en
          Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27
          de abril de 2016, relativo a la protección de las personas físicas en
          lo que respecta al tratamiento de datos personales y a la libre
          circulación de los mismos.
        </p>
        <p>
          • La utilización del servidor de correo del dominio y de las
          direcciones de correo electrónico para el envío de correo masivo no
          deseado.
        </p>
        <p>
          El usuario tiene toda la responsabilidad sobre el contenido de su web,
          la información transmitida y almacenada, los enlaces de hipertexto,
          las reivindicaciones de terceros y las acciones legales en referencia
          a propiedad intelectual, derechos de terceros y protección de menores.
        </p>
        <p>
          El usuario es responsable respecto a las leyes y reglamentos en vigor
          y las reglas que tienen que ver con el funcionamiento del servicio
          online, comercio electrónico, derechos de autor, mantenimiento del
          orden público, así como principios universales de uso de Internet.
        </p>
        <p>
          El usuario indemnizará a NEUTRWORKS 2020 S.L. por los gastos que
          generara la imputación de NEUTRWORKS 2020 S.L. en alguna causa cuya
          responsabilidad fuera atribuible al usuario, incluidos honorarios y
          gastos de defensa jurídica, incluso en el caso de una decisión
          judicial no definitiva.
        </p>
        <p className="legal-under">Protección de la información alojada</p>
        <p>
          NEUTRWORKS 2020 S.L. realiza copias de seguridad de los contenidos
          alojados en sus servidores, sin embargo no se responsabiliza de la
          pérdida o el borrado accidental de los datos por parte de los
          usuarios. De igual manera, no garantiza la reposición total de los
          datos borrados por los usuarios, ya que los citados datos podrían
          haber sido suprimidos y/o modificados durante el periodo del tiempo
          transcurrido desde la última copia de seguridad.
        </p>
        <p>
          Los servicios ofertados, excepto los servicios específicos de backup,
          no incluyen la reposición de los contenidos conservados en las copias
          de seguridad realizadas por NEUTRWORKS 2020 S.L., cuando esta pérdida
          sea imputable al usuario; en este caso, se determinará una tarifa
          acorde a la complejidad y volumen de la recuperación, siempre previa
          aceptación del usuario.
        </p>
        <p>
          La reposición de datos borrados sólo está incluida en el precio del
          servicio cuando la pérdida del contenido sea debida a causas
          atribuibles a NEUTRWORKS 2020 S.L.
        </p>
        <p className="legal-under">Comunicaciones comerciales</p>
        <p>
          En aplicación de la LSSI, NEUTRWORKS 2020 S.L. no enviará
          comunicaciones publicitarias o promocionales por correo electrónico u
          otro medio de comunicación electrónica equivalente que previamente no
          hubieran sido solicitadas o expresamente autorizadas por los
          destinatarios de las mismas.
        </p>
        <p>
          En el caso de usuarios con los que exista una relación contractual
          previa, NEUTRWORKS 2020 S.L. sí está autorizado al envío de
          comunicaciones comerciales referentes a productos o servicios de
          NEUTRWORKS 2020 S.L. que sean similares a los que inicialmente fueron
          objeto de contratación con el cliente.
        </p>
        <p>
          En todo caso, el usuario, tras acreditar su identidad, podrá solicitar
          que no se le haga llegar más información comercial a través de los
          canales de Atención al Cliente.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPage;
